import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { memo, useEffect, useMemo, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffItemIsEditPanel } from "./TariffItemIsEditPanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";

const TariffItemRightPanel = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const { isShowEditPanel } = generalProposalState;
    const generalProposalVM = useGeneralProposalVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const memoTariffItemTierTablePanel = useMemo(() => {
        return <TariffItemTierTablePanel />
    }, [])

    const memoTariffItemIsEditPanel = useMemo(() => {
        return <TariffItemIsEditPanel />
    }, [])

    useEffect(() => {
        setIsLoading(true);
        generalProposalVM.searchGeneralProposalItem(generalProposalState.currentSelectedRow).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [generalProposalState.currentSelectedRow, generalProposalVM]);

    const memoTariffItemRightPanel = useMemo(() => {
        return (<>
            <div className={`main-comp-wrapper${(isShowEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>
                {isLoading && <Loader Indicator="Spinner" size="medium" />}

                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={(isShowEditPanel) ? "60%" : "100%"}
                    rightSectionWidth={(isShowEditPanel) ? "40%" : "0%"}
                    leftChildren={memoTariffItemTierTablePanel}
                    rightChildren={memoTariffItemIsEditPanel}
                />
            </div>
        </>
        );
    }, [isLoading, isShowEditPanel, memoTariffItemIsEditPanel, memoTariffItemTierTablePanel])
    return <TableWrapper>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {memoTariffItemRightPanel}
    </TableWrapper>;
}

export default memo(TariffItemRightPanel);