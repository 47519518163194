import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useMemo } from "react";
import { DialogModal, FieldType, HPHButton, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";

const GeneralProposalRejectReasonModal: React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();

    const {searchCriteria,searchStatus,selectedRows,rejectReason, isShowRejectPad} = generalProposalState;
    
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Title;


    const onRejectClicked = () => {  
        selectedRows?.forEach(entity => {
            entity.rejectReason = rejectReason;
        });
        generalProposalVM.onReject(searchCriteria,searchStatus,selectedRows[0]);
    }

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"395px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={rejectReason || ''}
                fieldValue={rejectReason}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => generalProposalVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} 
                requiredFieldList={['rejectReason']}                    />
        </div>
    , [GENERAL_PROPOSAL_CONSTANT.REJECT_REASON, rejectReason, generalProposalVM])
    


    return <><DialogModal style={{  minWidth:'650px', minHeight:'650px' }}
    appendTo='self'
    showButton={false}
    visible={isShowRejectPad}
    positions="center"
    resizable={false}
    header={
        <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">Reject Reason</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={generalProposalVM.cancelRejectReasonModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                {memoRejectReason}
                </CriteriaItemContainer> 

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                {/* <HPHButton label={'View'} size={'Small'} theme={'Secondary'} onClick={onViewClicked}/> */}
                <HPHButton disabled={rejectReason.length===0} label={'Reject'} size={'Small'} theme={'Primary'} onClick={onRejectClicked}/>
            </div>
        }
    /></>;
};

export default memo(GeneralProposalRejectReasonModal);