import { SelectionChangedEvent } from "ag-grid-community";
import { GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import moment from "moment";
import { INITIAL_GENERAL_PROPOSAL_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalColumnDefinition";
import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Core } from "veronica-ui-component";
import { GroupButtons, Loader } from "veronica-ui-component/dist/component/core";


const GeneralProposalTablePanel: React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const gridRef: any = useRef(null);
    const [isLoading] = useState<boolean>(false);
    const { searchStatus, generalProposalList, isRejected, isRejectedCount, isPendingApproval, isPendingApprovalCount, isShowTariffItemTierPanel } = generalProposalState;
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
    const messageBarVM = useMessageBarVM();


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        generalProposalVM.updateSelectedRows(selectedRows);
    }, [generalProposalVM])

    const handleGroupButtonClick = useCallback(async (buttonId: string) => {
        generalProposalVM.onShowLoading();
        if (searchStatus && buttonId === searchStatus) {
            let newSearchCriteria = null;
            newSearchCriteria = { ...EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA }
            newSearchCriteria = {
                ...newSearchCriteria,
                effectiveDateFrom: moment().subtract(2, "years").startOf("year").toDate(),
                effectiveDateTo: moment().endOf('year').endOf('day').toDate(),
            };
            await generalProposalVM.searchAllGeneralProposal();
            await generalProposalVM.searchGeneralProposal(newSearchCriteria, "").then((data) => {
                generalProposalVM.onHideLoading();
            }).catch(error => {
                generalProposalVM.onHideLoading();
            }).finally(() => {
                generalProposalVM.onHideLoading();
            })
        } else {
            await generalProposalVM.onGroupButtonClick(buttonId, "").then((data) => {
                generalProposalVM.onHideLoading();
            }).catch(error => {
                generalProposalVM.onHideLoading();
            }).finally(() => {
                generalProposalVM.onHideLoading();
            })
        }

    }, [searchStatus, generalProposalVM]);

    const template = useMemo(() => {
        return (
            <>
                {/* <Core.CheckButton
            checked={isAll}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isAllCount>999?'999+':isAllCount}
            disabled={isAllCount===0}
            size="medium"
            inputId="isAll"
            label={GENERAL_PROPOSAL_CONSTANT.ALL_BUT} 
          /> */}
                <Core.CheckButton
                    checked={isRejected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
                    showBadge={true}
                    badgeValue={isRejectedCount > 999 ? '999+' : isRejectedCount}
                    disabled={isRejectedCount === 0}
                    size="medium"
                    inputId="isRejected"
                    label={GENERAL_PROPOSAL_CONSTANT.REJECTED_BUT}
                />
                <Core.CheckButton
                    checked={isPendingApproval}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
                    showBadge={true}
                    badgeValue={isPendingApprovalCount > 999 ? '999+' : isPendingApprovalCount}
                    disabled={isPendingApprovalCount === 0}
                    size="medium"
                    inputId="isPendingApproval"
                    label={GENERAL_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT}
                />
            </>
        )
    }, [GENERAL_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT, GENERAL_PROPOSAL_CONSTANT.REJECTED_BUT, handleGroupButtonClick, isPendingApproval, isPendingApprovalCount, isRejected, isRejectedCount])

    const isRowSelectable = useCallback((params: any) => {
        return !isShowTariffItemTierPanel;
    }, [isShowTariffItemTierPanel]);

    const memoGeneralProposalTable = useMemo(() => {
        return (
            <NbisTable
                id='general-proposal-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_COL_DEF?.slice()}
                data={transferRowData(generalProposalList?.map((gn: GeneralProposalEntity) => ({
                    ...gn,
                    flag_edit: gn.key === generalProposalState.gnProposalState.lastEditRowId,
                })))}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 145px)"
                ref={gridRef}
                headerLabel={
                    <GroupButtons
                        alignment="single-selection"
                        errorMessage=""
                        label=""
                        children={template}
                    />
                }
                messageVM={messageBarVM}
                handleRowSelectable={isRowSelectable}
            />
        );
    }, [generalProposalList, handleSelectionChange, template, messageBarVM, generalProposalState.gnProposalState.lastEditRowId, isRowSelectable])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isShowTariffItemTierPanel])

    return <><TableWrapper>
        {(generalProposalState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoGeneralProposalTable}
    </TableWrapper>
    </>;

}

export default memo(GeneralProposalTablePanel);
